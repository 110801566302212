'use client'

import React, { useState } from 'react'
import { supabase } from '../supabaseClient';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function SingUpPage() {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Exemplo de requisição no React usando fetch com JWT no cabeçalho
// const token = localStorage.getItem('supabase_token');
// const response = await fetch('http://localhost:5000/protected', {
//   method: 'GET',
//   headers: {
//     'Authorization': `Bearer ${token}`
//   }
// });
// const data = await response.json();
// console.log(data);
const goToLogin = () => {
  navigate('/');  // Redireciona para a página de login
};

const handleSignUp = async (e) => {
  e.preventDefault();
  setLoading(true);
  setError(null);

  // Verificar se email e senha estão preenchidos e se têm o formato correto
  if (!email || !password) {
    setError('Por favor, preencha todos os campos.');
    setLoading(false);
    return;
  }

  // Verificar formato do email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    setError('Por favor, insira um email válido.');
    setLoading(false);
    return;
  }

  try {
    const { user, error } = await supabase.auth.signUp({
      email: email,
      password: password,
    });

    if (error) throw error;

    console.log('Usuário criado:', user);
    alert('Cadastro realizado com sucesso. Por favor, verifique seu e-mail para confirmar a conta.');
  } catch (error) {
    if(error.message === "Database error saving new user")
      setError("Dominio de email invalido!");
    else{
      setError("Ocorreu um erro ao cadastrar novo usuario!");
    }
  } finally {
    setLoading(false);
  }
};

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gray-100 p-4 relative">
      <div className="w-full max-w-md bg-white border border-gray-200 rounded-lg p-6 space-y-6 shadow-md">
        <div className="space-y-2">
          <div className='flex'>
          <button
            onClick={goToLogin}  // Chama a função goToLogin ao clicar no botão
            className="flex px-2 py-2 text-sm text-gray-700 hover:bg-gray-100"
            role="menuitem"
          >
            <ArrowLeft size={20} className="" />  {/* Ícone de seta */}
          </button>
          <h1 className="text-2xl font-semibold text-gray-800">Cadastrar</h1>
        </div>
          <p className="text-gray-600">
            Insira seu e-mail e digite sua senha
          </p>
        </div>
        <form className="space-y-4" onSubmit={handleSignUp}>
          <div className="space-y-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              E-mail
            </label>
            <input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="seunome@exemplo.com"
              className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
            />
          </div>
          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Senha
              </label>
            </div>
            <div className="relative">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Digite a senha"
                className="w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-gray-900 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-transparent"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                aria-label={showPassword ? "Hide password" : "Show password"}
              >
                {showPassword ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <button
            type="submit"
            disabled={loading}
            className="w-full bg-black text-white font-medium py-2 px-4 rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            {loading ? 'Cadastrando...' : 'Cadastrar'}
          </button>
        </form>
        {error && <p className="text-center text-red-500 mt-2">{error}</p>}
      </div>
      <div className="absolute bottom-4 left-4 text-xs text-gray-500">
        Developed by DeepFire.AI
      </div>
    </div>
  )
}