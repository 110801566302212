// InvoicesPage.jsx - Página de relatórios e métricas de campanhas

import React, { useState, useEffect } from 'react';
import HeaderInvoices from '../components/Header/HeaderInvoices';
import MonthFilter from '../components/Filters/MonthFilter';
import MetricCard from '../components/Dashboard/MetricCard';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { apiUrl } from '../config';
import DualMetricCard from '../components/Dashboard/DualMetricCard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export default function InvoicesPage() {
  const [selectedMonth, setSelectedMonth] = useState(() => {
    const today = new Date();
    return today.toISOString().slice(0, 7);
  });

  // Add state for campaign data
  const [campaignData, setCampaignData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [campaignFilter, setCampaignFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 6;

  // Add state for monthly data
  const [monthlyData, setMonthlyData] = useState({
    total_mensagens: 0,
    price_menssage: 0
  });

  // Add useEffect to fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [year, month] = selectedMonth.split('-');
        
        // Fetch campaign data
        const campaignResponse = await fetch(
          `${apiUrl}/views/campaign_participants_and_sales?year=${year}&month=${month}`
        );
        const campaignData = await campaignResponse.json();
        
        // Fetch monthly summary
        const monthlyResponse = await fetch(
          `${apiUrl}/views/monthly_campaign_summary?year=${year}&month=${month}`
        );
        const monthlyDataResponse = await monthlyResponse.json();
        
        // Map campaign data
        const mappedCampaignData = campaignData.map(campaign => ({
          ...campaign,
          total_pedidos: campaign.total_ordered,
          links_gerados: campaign.total_ordered_link,
          pagamentos_concluidos: campaign.total_produtos_vendidos
        }));

        setCampaignData(mappedCampaignData);
        setMonthlyData(monthlyDataResponse[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth]); // Dependency array includes selectedMonth to refetch when month changes

  // const calculatePrice = (messages) => {
  //   if (!messages) return 1700;
  //   return messages <= 1000 ? 1700 : 1700 + (messages - 1000) * 0.06;
  // };

  // Função para truncar textos longos
  const truncateText = (text, maxLength = 20) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const filteredCampaigns = campaignData
    .filter(camp => camp.total_pedidos > 0)
    .filter(camp => 
      camp.campaign_name.toLowerCase().includes(campaignFilter.toLowerCase())
    )
    .sort((a, b) => b.total_pedidos - a.total_pedidos);

  const paginatedCampaigns = () => {
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    return filteredCampaigns.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  };

  const totalPages = Math.ceil(filteredCampaigns.length / ITEMS_PER_PAGE);

  if (isLoading) {
    return (
      <div className="flex h-screen">
        <div className="flex-1 flex flex-col">
          <HeaderInvoices />
          <main className="flex-1 overflow-hidden">
            <div className="h-full flex items-center justify-center">
              <div className="text-center">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
                <p className="mt-2 text-gray-600">Carregando dados...</p>
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-screen">
      <div className="flex-1 flex flex-col">
        <HeaderInvoices />
        <main className="flex-1 overflow-auto bg-gray-50">
          <div className="max-w-7xl mx-auto p-6">
            <div className="mb-6">
              <MonthFilter
                selectedMonth={selectedMonth}
                onMonthChange={setSelectedMonth}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
              <MetricCard
                title="Mensagens Enviadas"
                value={(monthlyData?.total_mensagens || 0).toLocaleString()}
              />
              <MetricCard
                title="Fatura Mensal"
                value={`R$ ${(monthlyData?.price_menssage || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`}
              />
              <MetricCard
                title="Participantes"
                value={campaignData.reduce((acc, camp) => acc + (camp.distinct_participants_count || 0), 0).toLocaleString()}
              />
              <MetricCard
                title="Produtos Vendidos"
                value={campaignData.reduce((acc, camp) => acc + (camp.total_produtos_vendidos || 0), 0).toLocaleString()}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <DualMetricCard
                title="Total de Pedidos"
                quantity={monthlyData?.total_ordered || 0}
                value={monthlyData?.total_ordered_price || 0}
              />

              <DualMetricCard
                title="Links de Pagamentos Gerados"
                quantity={monthlyData?.total_ordered_link || 0}
                value={monthlyData?.total_ordered_link_price || 0}
              />

              <DualMetricCard
                title="Pagamentos Concluídos"
                quantity={monthlyData?.total_produtos_vendidos || 0}
                value={monthlyData?.total_produtos_vendidos_price || 0}
              />
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-10">
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 h-[500px]">
                <h3 className="text-lg font-medium mb-4">Mensagens por Campanha</h3>
                <Bar
                  data={{
                    labels: campaignData
                      .filter(camp => camp.total_mensagens > 0)
                      .sort((a, b) => b.total_mensagens - a.total_mensagens)
                      .map(camp => camp.campaign_name),
                    datasets: [{
                      label: 'Total de Mensagens',
                      data: campaignData
                        .filter(camp => camp.total_mensagens > 0)
                        .sort((a, b) => b.total_mensagens - a.total_mensagens)
                        .map(camp => camp.total_mensagens),
                      backgroundColor: 'rgba(59, 130, 246, 0.5)',
                      borderColor: 'rgb(59, 130, 246)',
                      borderWidth: 1
                    }]
                  }}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          title: (tooltipItems) => {
                            return campaignData
                              .sort((a, b) => b.total_mensagens - a.total_mensagens)
                              [tooltipItems[0].dataIndex].campaign_name;
                          },
                          label: (context) => {
                            return `Total de Mensagens: ${context.raw.toLocaleString()}`;
                          }
                        }
                      },
                      datalabels: {
                        color: 'black',
                        anchor: 'end',
                        align: 'end',
                        offset: 4,
                        font: {
                          size: 11
                        },
                        formatter: (value) => {
                          return value ? value.toLocaleString() : '';
                        },
                        display: (context) => context.dataset.data[context.dataIndex] > 0
                      }
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: (value) => value.toLocaleString()
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function(value) {
                            const label = this.getLabelForValue(value);
                            return truncateText(label, 30);
                          },
                          font: {
                            size: 11
                          }
                        }
                      }
                    },
                    layout: {
                      padding: {
                        right: 50
                      }
                    }
                  }}
                />
              </div>
              
              <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 h-[500px]">
                <h3 className="text-lg font-medium mb-4">Vendas por Campanha</h3>
                <Bar
                  data={{
                    labels: campaignData
                      .filter(camp => camp.total_produtos_vendidos > 0)
                      .sort((a, b) => b.total_produtos_vendidos - a.total_produtos_vendidos)
                      .map(camp => camp.campaign_name),
                    datasets: [{
                      label: 'Produtos Vendidos',
                      data: campaignData
                        .filter(camp => camp.total_produtos_vendidos > 0)
                        .sort((a, b) => b.total_produtos_vendidos - a.total_produtos_vendidos)
                        .map(camp => camp.total_produtos_vendidos),
                      backgroundColor: 'rgba(16, 185, 129, 0.5)',
                      borderColor: 'rgb(16, 185, 129)',
                      borderWidth: 1
                    }]
                  }}
                  options={{
                    indexAxis: 'y',
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          title: (tooltipItems) => {
                            return campaignData
                              .sort((a, b) => b.total_produtos_vendidos - a.total_produtos_vendidos)
                              [tooltipItems[0].dataIndex].campaign_name;
                          },
                          label: (context) => {
                            return `Produtos Vendidos: ${context.raw.toLocaleString()}`;
                          }
                        }
                      },
                      datalabels: {
                        color: 'black',
                        anchor: 'end',
                        align: 'end',
                        offset: 4,
                        font: {
                          size: 11
                        },
                        formatter: (value) => {
                          return value ? value.toLocaleString() : '';
                        },
                        display: (context) => context.dataset.data[context.dataIndex] > 0
                      }
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: (value) => value.toLocaleString()
                        }
                      },
                      y: {
                        grid: {
                          display: false
                        },
                        ticks: {
                          callback: function(value) {
                            const label = this.getLabelForValue(value);
                            return truncateText(label, 30);
                          },
                          font: {
                            size: 11
                          }
                        }
                      }
                    },
                    layout: {
                      padding: {
                        right: 50
                      }
                    }
                  }}
                />
              </div>
            </div>

            {/* Gráficos de conversão por campanha */}
            <div className="mb-6">
              <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 mb-6">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-semibold">Conversão por Campanha</h2>
                  <div className="flex items-center gap-4">
                    <div className="relative w-72">
                      <input
                        type="text"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Buscar campanha..."
                        value={campaignFilter}
                        onChange={(e) => {
                          setCampaignFilter(e.target.value);
                          setCurrentPage(1); // Reset para primeira página ao filtrar
                        }}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <svg
                          className="w-5 h-5 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                          />
                        </svg>
                      </div>
                    </div>
                    
                    {/* Informação de resultados */}
                    <span className="text-sm text-gray-500">
                      Mostrando {Math.min(currentPage * ITEMS_PER_PAGE, filteredCampaigns.length)} de {filteredCampaigns.length} campanhas
                    </span>
                  </div>
                </div>
                
                {filteredCampaigns.length === 0 ? (
                  <div className="text-center py-8 text-gray-500">
                    Nenhuma campanha encontrada com o termo "{campaignFilter}"
                  </div>
                ) : (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                      {paginatedCampaigns().map((campaign) => (
                        <div key={campaign.campaign_name} className="bg-white p-6 rounded-lg shadow-sm border border-gray-200 h-[400px]">
                          <h3 className="text-lg font-medium mb-4 truncate" title={campaign.campaign_name}>
                            {campaign.campaign_name}
                          </h3>
                          <Bar
                            data={{
                              labels: ['Total de Pedidos', 'Links Gerados', 'Pagamentos Concluídos'],
                              datasets: [{
                                data: [
                                  campaign.total_pedidos,
                                  campaign.links_gerados,
                                  campaign.pagamentos_concluidos
                                ],
                                backgroundColor: [
                                  'rgba(99, 102, 241, 0.8)',
                                  'rgba(99, 102, 241, 0.6)',
                                  'rgba(99, 102, 241, 0.4)'
                                ],
                                borderColor: 'rgb(99, 102, 241)',
                                borderWidth: 1
                              }]
                            }}
                            options={{
                              indexAxis: 'y',
                              responsive: true,
                              maintainAspectRatio: false,
                              plugins: {
                                legend: {
                                  display: false
                                },
                                tooltip: {
                                  callbacks: {
                                    label: (context) => {
                                      const value = context.raw;
                                      let percentage;
                                      
                                      if (context.dataIndex === 0) { // Total de Pedidos
                                        percentage = '100';
                                      } else if (context.dataIndex === 1) { // Links Gerados
                                        percentage = ((campaign.links_gerados / campaign.total_pedidos) * 100).toFixed(1);
                                      } else { // Pagamentos Concluídos
                                        percentage = ((campaign.pagamentos_concluidos / campaign.total_pedidos) * 100).toFixed(1);
                                      }
                                      
                                      return `${context.label}: ${value.toLocaleString()} (${percentage}%)`;
                                    }
                                  }
                                },
                                datalabels: {
                                  color: 'white',
                                  anchor: 'center',
                                  align: 'center',
                                  font: {
                                    size: 11,
                                    weight: 'bold'
                                  },
                                  formatter: (value, context) => {
                                    let percentage;
                                    
                                    if (context.dataIndex === 0) {
                                      percentage = '100';
                                    } else if (context.dataIndex === 1) {
                                      percentage = ((campaign.links_gerados / campaign.total_pedidos) * 100).toFixed(1);
                                    } else {
                                      percentage = ((campaign.pagamentos_concluidos / campaign.total_pedidos) * 100).toFixed(1);
                                    }
                                    
                                    return value ? [
                                      value.toLocaleString(),
                                      `${percentage}%`
                                    ] : '';
                                  },
                                  display: (context) => context.dataset.data[context.dataIndex] > 0
                                }
                              },
                              scales: {
                                x: {
                                  grid: {
                                    display: false
                                  },
                                  ticks: {
                                    callback: (value) => value.toLocaleString()
                                  }
                                },
                                y: {
                                  grid: {
                                    display: false
                                  }
                                }
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>

                    {/* Controles de paginação */}
                    {totalPages > 1 && (
                      <div className="flex justify-center items-center mt-6 gap-2">
                        <button
                          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                          disabled={currentPage === 1}
                          className={`px-3 py-1 rounded ${
                            currentPage === 1
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                        >
                          Anterior
                        </button>
                        
                        {/* Números das páginas */}
                        <div className="flex gap-1">
                          {[...Array(totalPages)].map((_, i) => (
                            <button
                              key={i + 1}
                              onClick={() => setCurrentPage(i + 1)}
                              className={`px-3 py-1 rounded ${
                                currentPage === i + 1
                                  ? 'bg-blue-500 text-white'
                                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                              }`}
                            >
                              {i + 1}
                            </button>
                          ))}
                        </div>

                        <button
                          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                          disabled={currentPage === totalPages}
                          className={`px-3 py-1 rounded ${
                            currentPage === totalPages
                              ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                              : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                        >
                          Próxima
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
