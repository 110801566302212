import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import ProductsPage from './pages/ProductPage';
import CampaignPage from './pages/CampaignPage';
import InvoicesPage from './pages/InvoicesPage';
import ProtectedRoute from './components/ProtectedRoute';
import SignInPage from './pages/SingInPage';
import SignUpPage from './pages/SingUpPage';
import "react-datepicker/dist/react-datepicker.css";



function App() {
  // Verifica se o usuário está autenticado
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento

  useEffect(() => {
    // Verificar a sessão do usuário
    //const teste = supabase.auth.getSession();
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false); // Atualiza o estado quando a sessão for carregada
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => {
      subscription.unsubscribe(); // Limpa a subscrição ao desmontar
    };
  }, []);

  if (loading) {
    return <div>Carregando...</div>; // Você pode exibir um carregando enquanto o estado não for resolvido
  }

  return (
    <Router>
      <div className="flex h-screen">
        {/* Condicional para exibir ou não o Sidebar */}
        {session && <Sidebar />}
  
        <div className="flex flex-col flex-1">
          <Routes>
            {/* Rota de SignUp */}
            <Route path="/signup" element={<SignUpPage />} />
            
            {/* Se houver sessão, exibe o conteúdo principal, senão o SignInPage */}
            <Route 
              path="/" 
              element={session ? <Navigate to="/products" /> : <SignInPage />} 
            />
  
            {/* Rotas protegidas */}
            <Route path="/products"  element={<ProtectedRoute element= {<ProductsPage />} />} />
            <Route path="/campaigns" element={<ProtectedRoute element= {<CampaignPage />} />} />
            <Route path="/invoices"  element={<ProtectedRoute element= {<InvoicesPage />} />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}


export default App;
