import React, { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
import { Calendar } from 'lucide-react';
import "react-datepicker/dist/react-datepicker.css";

// Adicione estes estilos ao seu CSS global ou como um módulo CSS
const customStyles = `
  .custom-datepicker {
    font-family: inherit;
  }

  .custom-datepicker .react-datepicker__month-container {
    float: none;
    width: 280px;
  }

  .custom-datepicker .react-datepicker__header {
    background-color: #f8fafc;
    border-bottom: 1px solid #e2e8f0;
    padding: 0.5rem;
  }

  .custom-datepicker .react-datepicker__month {
    margin: 0;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 0.25rem;
  }

  .custom-datepicker .react-datepicker__month-wrapper {
    display: contents !important;
  }

  .custom-datepicker .react-datepicker__month-text {
    width: auto !important;
    padding: 0.5rem 0.25rem;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.375rem;
    font-size: 0.875rem;
  }

  .custom-datepicker .react-datepicker__month-text:hover {
    background-color: #e2e8f0;
  }

  .custom-datepicker .react-datepicker__month-text--selected {
    background-color: #3b82f6 !important;
    color: white;
  }

  .custom-datepicker .react-datepicker__month-text--keyboard-selected:not(.react-datepicker__month-text--selected) {
    background-color: #93c5fd;
    color: white;
  }
`;

registerLocale('pt-BR', ptBR);

export default function MonthFilter({ selectedMonth, onMonthChange }) {
  const [isOpen, setIsOpen] = useState(false);

  // Adiciona os estilos ao documento apenas uma vez
  React.useEffect(() => {
    if (!document.getElementById('datepicker-styles')) {
      const styleSheet = document.createElement('style');
      styleSheet.id = 'datepicker-styles';
      styleSheet.textContent = customStyles;
      document.head.appendChild(styleSheet);
    }
  }, []);

  const handleMonthChange = (date) => {
    const adjustedDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      1
    );
    const formattedDate = format(adjustedDate, 'yyyy-MM');
    onMonthChange(formattedDate);
    console.log(formattedDate);
    setIsOpen(false);
  };

  const toggleCalendar = () => {
    setIsOpen(!isOpen);
  };

  const selectedDate = selectedMonth ? 
    new Date(
      new Date(selectedMonth).getFullYear(),
      new Date(selectedMonth).getMonth() + 1,
      1
    ) : 
    new Date();

  const formattedDisplayDate = format(selectedDate, 'MMMM/yyyy', { locale: ptBR });

  return (
    <div className="relative">
      <div className="flex items-center gap-2 mb-4">
        <h2 className="text-lg font-semibold text-gray-700">Período:</h2>
        <div className="relative">
          <button
            onClick={toggleCalendar}
            className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <Calendar className="w-5 h-5 text-gray-500" />
            <span className="text-gray-700 capitalize">
              {formattedDisplayDate}
            </span>
          </button>

          <div className={`absolute z-10 mt-1 ${isOpen ? 'block' : 'hidden'}`}>
            <DatePicker
              selected={selectedDate}
              onChange={handleMonthChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              inline
              locale="pt-BR"
              calendarClassName="custom-datepicker bg-white border border-gray-200 rounded-lg shadow-lg"
              wrapperClassName="!block"
              onClickOutside={() => setIsOpen(false)}
              showPopperArrow={false}
              fixedHeight
              renderCustomHeader={({
                date,
                decreaseYear,
                increaseYear,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled
              }) => (
                <div className="flex items-center justify-between px-2 py-2">
                  <button
                    onClick={decreaseYear}
                    disabled={prevMonthButtonDisabled}
                    type="button"
                    className="p-1 hover:bg-gray-100 rounded-full disabled:opacity-50"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>
                  <div className="text-lg font-semibold">
                    {format(date, 'yyyy')}
                  </div>
                  <button
                    onClick={increaseYear}
                    disabled={nextMonthButtonDisabled}
                    type="button"
                    className="p-1 hover:bg-gray-100 rounded-full disabled:opacity-50"
                  >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
} 