import React, { useState } from 'react';
import { PlusCircle, Download } from 'lucide-react';
import ProductModal from '../Modal/ProductModal';
import axios from 'axios';
import { apiUrl } from '../../config';

export default function HeaderProducts({ pageName = "Produtos", onAddProduct }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Função para exportar produtos para Excel
  const handleExportExcel = async () => {
    try {
      const response = await axios.get(`${apiUrl}/products/export`, {
        responseType: 'blob',
      });

      // Criar um link temporário para o download do arquivo
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `produtos_${new Date().toISOString().split('T')[0]}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao exportar produtos:', error);
    }
  };

  return (
    <div className="flex flex-col">
      <header className="sticky top-0 z-10 flex h-16 items-center justify-between border-b bg-background px-4 md:px-6">
        <h1 className="text-lg font-semibold">{pageName}</h1>
        <div className="flex gap-2">
          <button
            onClick={handleExportExcel}
            className="inline-flex items-center justify-center rounded-md bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow transition-colors hover:bg-gray-700 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          >
            <Download className="mr-2 h-4 w-4" />
            Exportar Excel
          </button>
          <button
            onClick={openModal}
            className="inline-flex items-center justify-center rounded-md bg-primary px-4 py-2 text-sm font-medium text-primary-foreground shadow transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50"
          >
            <PlusCircle className="mr-2 h-4 w-4" />
            Novo Produto
          </button>
        </div>
      </header>

      {isModalOpen && <ProductModal onClose={closeModal} onAddProduct={onAddProduct} />}
    </div>
  );
}
