'use client'

import React, { useState } from 'react'
import { X, Plus } from 'lucide-react'
import SuccessToast from '../Toast/SuccessToast'
import FailureToast from '../Toast/FailureToast'
import { apiUrl } from '../../config'

const ProductModal = ({ onClose, product = null, onSave, onAddProduct }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [name, setName] = useState(product ? product.name : '')
  const [code, setCode] = useState(product ? product.product_code : '')
  const [price, setPrice] = useState(product ? formatPrice(product.price) : '') // Garantir que price seja string desde o início
  const [sizes, setSizes] = useState(product ? product.size.split('; ') : [])
  const [currentSize, setCurrentSize] = useState('')
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  const [showToastSuccess, setShowSuccessToast] = useState(false)
  const [showToastFailure, setShowFailureToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  // Adicione um estado para controlar erro de validação
  const [photoError, setPhotoError] = useState('');

  //  // Preencher os campos do formulário se for edição
  //  useEffect(() => {
  //   if (product) {
  //     setName(product.name)
  //     setCode(product.product_code)
  //     setPrice(formatPrice(product.price * 100)) // Supondo que o preço no banco seja em centavos
  //     setSizes(product.size ? product.size.split('; ') : [])
  //     setFile(null) // Não vamos sobrepor a foto do produto, pois isso é opcional para edição
  //   }
  // }, [product])

  function formatPrice(value) {
    if (typeof value !== 'string') {
      value = String(value) // Converte qualquer valor não string para string
    }
    const numericValue = value.replace(/\D/g, '') // Remove caracteres não numéricos
    const formattedValue = (Number(numericValue) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return formattedValue
  }

  const handlePriceChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, '')
    if (rawValue.length <= 6) {
      setPrice(formatPrice(rawValue))
    }
  }

  const handleAddSize = () => {
    if (currentSize && !sizes.includes(currentSize)) {
      setSizes([...sizes, currentSize])
      setCurrentSize('')
    }
  }

  const handleRemoveSize = (sizeToRemove) => {
    setSizes(sizes.filter((size) => size !== sizeToRemove))
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (file && allowedTypes.includes(file.type)) {
      setFile(file);
      setPhotoError('');
    } else {
      setPhotoError('Por favor selecione uma imagem nos formatos: JPG, JPEG, PNG');
    }
  }

  const handleRemovePhoto = () => {
    setFile(null) // Limpar o arquivo
  }

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  }

  const handleFormSubmit = async () => {
    // Reset error state
    setPhotoError('');

    // Validate photo
    if (!file && !product?.photo) { // Permite edição sem mudar a foto
      setPhotoError('A foto do produto é obrigatória');
      return;
    }

    setLoading(true);
    const sizeString = sizes.join('; ');
    const photoUrl = file ? await uploadPhoto(file) : product?.photo || ''; 

    // Validação da URL da foto
    if (!photoUrl || !photoUrl.startsWith('https://')) {
      setPhotoError('Não foi possível fazer o upload da foto, por favor tente novamente.');
      setLoading(false);
      return;
    }

    const productData = {
      id: product ? product.id : null,
      name,
      size: sizeString,
      photo: photoUrl,
      product_code: code,
      price: parseFloat(price.replace(/\D/g, '')) / 100, // Converte o preço para formato numérico
    }

    const url = product ? `${apiUrl}/products/product/${product.id}` : `${apiUrl}/products/set_product`
    const method = product ? 'PUT' : 'POST'

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(productData),
      })

      const result = await response.json()

      if (response.ok) {
        setToastMessage(product ? 'Produto atualizado com sucesso!' : 'Produto criado com sucesso!')
        //onAddProduct(productData);
        product ? onSave(productData) : onAddProduct(productData);
        setShowSuccessToast(true)
        handleClose()
      } else {
        setToastMessage(result.error)
        setShowFailureToast(true)
      }
    } catch (error) {
      setLoading(false)
      setToastMessage('Ocorreu um erro ao salvar o produto')
      setShowFailureToast(true)
    } finally {
      setLoading(false)  // Desativa o loader
    }
  }

  // Função para fazer upload da foto para o servidor
  const uploadPhoto = async (file) => {
    const formData = new FormData()
    formData.append('photo', file)

    try {
      const response = await fetch(`${apiUrl}/products/upload`, {
        method: 'POST',
        body: formData,
      })

      const result = await response.json()

      if (response.ok) {
        return result.photoUrl // Supondo que o servidor retorne o campo `photoUrl` com a URL da foto
      } else {
        console.error('Erro ao fazer upload da foto:', result)
        return ''
      }
    } catch (error) {
      console.error('Erro ao fazer upload da foto:', error)
      return ''
    }
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-md p-6">
        <h2 className="text-lg font-semibold mb-4">{product ? 'Criar Produto' : 'Editar Produto'}</h2>
        <form className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Nome
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome do Produto"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="code" className="block text-sm font-medium text-gray-700 mb-1">
              Código
            </label>
            <input
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder="Código do Produto"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="price" className="block text-sm font-medium text-gray-700 mb-1">
              Preço
            </label>
            <input
              type="text"
              id="price"
              value={price}
              onChange={handlePriceChange}
              placeholder="Preço Inicial"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="sizes" className="block text-sm font-medium text-gray-700 mb-1">
              Tamanhos
            </label>
            <div className="flex items-center space-x-2">
              <input
                type="text"
                id="sizes"
                value={currentSize}
                onChange={(e) => setCurrentSize(e.target.value.toUpperCase())}
                placeholder="Adicione um Tamanho"
                className="flex-grow px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="button"
                onClick={handleAddSize}
                className="p-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <Plus className="w-5 h-5" />
              </button>
            </div>
            <div className="flex flex-wrap gap-2 mt-2">
              {sizes.map((size) => (
                <span
                  key={size}
                  className="inline-flex items-center px-2 py-1 rounded-full text-sm font-medium bg-secondary text-secondary-foreground"
                >
                  {size}
                  <button
                    type="button"
                    onClick={() => handleRemoveSize(size)}
                    className="ml-1 inline-flex items-center p-0.5 text-secondary-foreground hover:bg-secondary/80 hover:text-secondary-foreground rounded-full"
                  >
                    <X className="w-3 h-3" />
                  </button>
                </span>
              ))}
            </div>
          </div>
          <div>
            <label htmlFor="photo" className="block text-sm font-medium text-gray-700 mb-1">
              Foto <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              id="photo"
              onChange={handleFileChange}
              className="hidden"
              accept="image/*"
            />
            <label
              htmlFor="photo"
              className={`cursor-pointer inline-flex items-center px-4 py-2 border ${
                photoError ? 'border-red-500' : 'border-gray-300'
              } rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                photoError ? 'focus:ring-red-500' : 'focus:ring-blue-500'
              }`}
            >
              Escolher arquivo
            </label>
            {photoError && (
              <p className="mt-1 text-sm text-red-500">
                {photoError}
              </p>
            )}
            {(file || product?.photo) && (
              <div className="mt-2 relative w-32 h-32 mx-auto">
                <img
                  src={file ? URL.createObjectURL(file) : product.photo}
                  alt="Foto do Produto"
                  className="w-full h-full object-cover rounded-md"
                />
                <button
                  type="button"
                  onClick={handleRemovePhoto}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </form>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Fechar
          </button>
          <button
            onClick={handleFormSubmit}
            disabled={loading}
            className="px-4 py-2 bg-primary text-primary-foreground hover:bg-primary/90 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {loading ? 'Salvando...' : 'Salvar Produto'}
          </button>
        </div>
      </div>

      {showToastSuccess && <SuccessToast message={toastMessage} onClose={() => setShowSuccessToast(false)} />}
      {showToastFailure && <FailureToast message={toastMessage} onClose={() => setShowFailureToast(false)} />}
    </div>
  )
}

export default ProductModal