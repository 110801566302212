'use client'

import React, { useState, useRef, useEffect } from 'react';
import { X, ChevronDown, ChevronUp, Upload } from 'lucide-react';
import axios from 'axios';
import FailureToast from '../Toast/FailureToast'
import { apiUrl } from '../../config';

const CampaignModal = ({ onClose, onAddCampaign }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [selectedWhatsappGroups, setSelectedWhatsappGroups] = useState([])
  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')
  const [deliveryPrice, setDeliveryPrice] = useState('R$ 20,00')
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isWhatsappGroupsOpen, setIsWhatsappGroupsOpen] = useState(false)
  const whatsappGroupsRef = useRef(null)

  const [availableProducts, setAvailableProducts] = useState([]);
  const [whatsappGroups, setWhatsappGroups] = useState([]);
  const [loading, setLoading] = useState(false)

  const [showToastFailure, setShowFailureToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  const [selectedMethod, setSelectedMethod] = useState(null);
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Requisição para produtos
        const productsResponse = await axios.get(`${apiUrl}/products/get_products`);
        setAvailableProducts(productsResponse.data);

        // Requisição para grupos do WhatsApp
        const groupsResponse = await axios.get(`${apiUrl}/campaignMessages/groups_whatsapp`);
        setWhatsappGroups(groupsResponse.data.groups_whatsapp);
      } catch (error) {
        console.error('Erro ao carregar dados:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (whatsappGroupsRef.current && !whatsappGroupsRef.current.contains(event.target)) {
        setIsWhatsappGroupsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const formatDateTime = (date, time) => {
    return `${date} ${time}:00`;
  };


  const handleSubmit = async () => {
    console.log(selectedProducts)
    if (!name || !description || !startDate || !startTime || !endDate || !endTime || selectedWhatsappGroups.length === 0) {
      setToastMessage('Por favor, preencha todos os campos.');
      setShowFailureToast(true);
      setLoading(false);
      return; // Impede o envio se algum campo obrigatório não estiver preenchido
    }

    if (selectedProducts.length === 0) {
      setToastMessage('Por favor, selecione pelo menos um produto.');
      setShowFailureToast(true);
      setLoading(false);
      return; // Impede o envio se não houver produtos selecionados
    }

   

    try {
      setLoading(true)
      const startsAt = formatDateTime(startDate, startTime);
      const endsAt = formatDateTime(endDate, endTime);

      // Enviar dados para criar a campanha
      const campaignData = {
        name,
        description,
        starts_at: startsAt,
        ends_at: endsAt,
        delivery_price:  parseFloat(deliveryPrice.replace('R$', '').replace(',', '.')),
        groups: selectedWhatsappGroups        
      };
      const response = await axios.post(`${apiUrl}/campaigns/set_campaign`, campaignData);
      const campaignId = response.data.id;
        
         // Agora, envie os produtos
         const productsData = selectedProducts.map(product => {
          const sizes = Object.keys(product.quantities).map(size => ({
            product_size: size,
            campaign_price: parseFloat(product.campaignPrice.replace('R$', '').replace(',', '.')), // Convertendo para número
            quantity: parseInt(product.quantities[size], 10),
            total_sold: 0,
          }));
  
          return {
            product_id: product.id,
            sizes,
          };
        });
  
        const productsPayload = {
          campaign_id: campaignId,
          products: productsData,
        };
  
        // Enviar os produtos para a campanha
        const productsResponse = await axios.post(`${apiUrl}/campaignProducts/set_campaign_product`, productsPayload);
        
        
        if (productsResponse.ok || response.data) {
          onAddCampaign(response.data);
          // onShowToastSuccess('Campanha criada com sucesso!');          
          handleClose()
        } else {
          setLoading(false)
          setToastMessage("Erro ao criar campanha ou adicionar produtos");
          setShowFailureToast(true);
          setLoading(false)
        }

    } catch (error) {
      setToastMessage('Ocorreu um erro ao criar campanha')
      setShowFailureToast(true)
      setLoading(false)
    } 
  };

  const handleProductSelect = (productId) => {
    const product = availableProducts.find(p => p.id === parseInt(productId))
    if (product && !selectedProducts.some(p => p.id === product.id)) {
      setSelectedProducts(prevProducts => [...prevProducts, { ...product, campaignPrice: '', quantities: {} }])
    }
  }

  const handleProductRemove = (productId) => {
    setSelectedProducts(prevProducts => prevProducts.filter(p => p.id !== productId))
  }

  const handleCampaignPriceChange = (productId, price) => {
    const numericValue = price.replace(/\D/g, '')
    const formattedValue = (Number(numericValue) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    setSelectedProducts(prevProducts => prevProducts.map(p => 
      p.id === productId ? { ...p, campaignPrice: formattedValue } : p
    ))
  }

  const handleCampaignDeliveryPrice = (price) => {
    const numericValue = price.replace(/\D/g, '')
    const formattedValue = (Number(numericValue) / 100).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    setDeliveryPrice(formattedValue)
  }

  const handleQuantityChange = (productId, size, quantity) => {
    setSelectedProducts(prevProducts => prevProducts.map(p => 
      p.id === productId ? { ...p, quantities: { ...p.quantities, [size]: quantity } } : p
    ))
  }

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  }

  const handleDateChange = (e, setDate) => {
    setDate(e.target.value)
  }

  const handleTimeChange = (e, setTime) => {
      setTime(e.target.value)
  }

  const handleWhatsappGroupToggle = (groupId) => {
    setSelectedWhatsappGroups(prevSelected => 
      prevSelected.includes(groupId)
        ? prevSelected.filter(id => id !== groupId)
        : [...prevSelected, groupId]
    )
  }

  const handleSelectAllWhatsappGroups = () => {
    if (selectedWhatsappGroups.length === whatsappGroups.length) {
      setSelectedWhatsappGroups([])
    } else {
      setSelectedWhatsappGroups(whatsappGroups.map(group => group.id))
    }
  }

  const isAllWhatsappGroupsSelected = selectedWhatsappGroups.length === whatsappGroups.length

  const handleFileUpload = async (event) => {
    try {
      console.log('handleFileUpload')
      const file = event.target.files[0];
      if (!file) return;

      setIsImporting(true);
      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${apiUrl}/products/extract_products`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const processedProducts = response.data;
      setSelectedProducts(processedProducts);
    } catch (error) {
      console.error('Erro ao importar produtos:', error);
      setToastMessage('Erro ao importar produtos. Verifique o arquivo e tente novamente.');
      setShowFailureToast(true);
    } finally {
      setIsImporting(false);
    }
  };

  const handleMethodChange = (method) => {
    setSelectedMethod(method);
    setSelectedProducts([]); // Limpa os produtos selecionados ao mudar o método
  };

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black/30 backdrop-blur-sm"></div>
      <div className="relative bg-white rounded-lg shadow-lg w-full max-w-2xl p-6 overflow-y-auto max-h-[90vh]">
        <h2 className="text-lg font-semibold mb-4">Criar Campanha</h2>
        <form className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
              Nome
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nome da campanha"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>
          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
              Descrição
            </label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Descrição interna para lembrar do que se trata"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>
          <div>
            <label htmlFor="deliveryPrice" className="block text-sm font-medium text-gray-700 mb-1">
              Preço do Frete
            </label>
            <input
              type="text"
              id="deliveryPrice"
              value={deliveryPrice}
              onChange={(e) => handleCampaignDeliveryPrice(e.target.value)}
              placeholder="R$ 0,00"
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400"
            />
          </div>
          <div ref={whatsappGroupsRef}>
            <label htmlFor="whatsappGroups" className="block text-sm font-medium text-gray-700 mb-1">
              Grupos do WhatsApp
            </label>
            <div className="relative">
              <button
                type="button"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-left flex justify-between items-center"
                onClick={(e) => {
                  e.stopPropagation()
                  setIsWhatsappGroupsOpen(!isWhatsappGroupsOpen)
                }}
              >
                <span className="text-gray-700">
                  {selectedWhatsappGroups.length === 0
                    ? 'Selecione os grupos para disparar a campanha'
                    : `${selectedWhatsappGroups.length} grupo${selectedWhatsappGroups.length !== 1 ? 's' : ''} selecionado${selectedWhatsappGroups.length !== 1 ? 's' : ''}`}
                </span>
                <ChevronDown className="w-4 h-4 text-gray-500" />
              </button>
              {isWhatsappGroupsOpen && (
                <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg">
                  <label className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer border-b border-gray-200">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                      checked={isAllWhatsappGroupsSelected}
                      onChange={handleSelectAllWhatsappGroups}
                    />
                    <span className="ml-2 text-gray-700 font-medium">Selecionar todos</span>
                  </label>
                  {Array.isArray(whatsappGroups) && whatsappGroups.map(group => (
                    <label key={group.id} className="flex items-center px-3 py-2 hover:bg-gray-100 cursor-pointer">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                        checked={selectedWhatsappGroups.includes(group.id)}
                        onChange={() => handleWhatsappGroupToggle(group.id)}
                      />
                      <span className="ml-2 text-gray-700">{group.subject}</span>
                    </label>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                Dia de início
              </label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => handleDateChange(e, setStartDate)}
                placeholder="dd/mm/aaaa"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-gray-400"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 mb-1">
                Horário de início
              </label>
              <input
                type="time"
                id="startTime"
                value={startTime}
                onChange={(e) => handleTimeChange(e, setStartTime)}
                min="00:00"
                max="23:59"
                placeholder="--:--"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-gray-400"
              />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                Dia de fim
              </label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => handleDateChange(e, setEndDate)}
                placeholder="dd/mm/aaaa"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-gray-400"
              />
            </div>
            <div className="flex-1">
              <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 mb-1">
                Horário de fim
              </label>
              <input
                type="time"
                id="endTime"
                value={endTime}
                onChange={(e) => handleTimeChange(e, setEndTime)}
                placeholder="--:--"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 placeholder-gray-400"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-4">
              Como deseja adicionar os produtos?
            </label>
            
            {!selectedMethod ? (
              <div className="grid grid-cols-2 gap-4 mb-6">
                <button
                  type="button"
                  onClick={() => handleMethodChange('manual')}
                  className="flex flex-col items-center justify-center p-6 border-2 border-gray-300 rounded-lg hover:border-gray-400 transition-colors"
                >
                  <ChevronDown className="w-8 h-8 mb-2 text-gray-600" />
                  <span className="text-sm font-medium">Cadastro Manual</span>
                </button>
                
                <div 
                  onClick={() => handleMethodChange('excel')}
                  className="flex flex-col items-center justify-center p-6 border-2 border-gray-300 rounded-lg hover:border-gray-400 transition-colors cursor-pointer"
                >
                  <Upload className="w-8 h-8 mb-2 text-gray-600" />
                  <span className="text-sm font-medium">Importar Excel</span>
                  {selectedMethod === 'excel' && (
                    <input
                      type="file"
                      accept=".xlsx,.xls"
                      className="hidden"
                      onChange={handleFileUpload}
                    />
                  )}
                </div>
              </div>
            ) : selectedMethod === 'excel' ? (
              <div className="text-center">
                <input
                  type="file"
                  accept=".xlsx,.xls"
                  className="hidden"
                  onChange={handleFileUpload}
                  id="fileInput"
                />
                {isImporting ? (
                  <div className="flex flex-col items-center justify-center space-y-2">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                    <p className="text-sm text-gray-600">Importando produtos...</p>
                  </div>
                ) : (
                  <>
                    <div className="mb-4">
                      <label 
                        htmlFor="fileInput"
                        className="inline-flex items-center px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 cursor-pointer"
                      >
                        <Upload className="w-4 h-4 mr-2" />
                        Selecionar arquivo
                      </label>
                    </div>
                    <p className="text-sm text-gray-600 mb-4">
                      Produtos importados: {selectedProducts.length}
                    </p>
                    <button
                      onClick={() => handleMethodChange(null)}
                      className="text-sm text-gray-600 hover:text-gray-800"
                    >
                      Alterar método de adição
                    </button>
                  </>
                )}
              </div>
            ) : (
              <>
                <div>
                  <label htmlFor="selectProduct" className="block text-sm font-medium text-gray-700 mb-1">
                    Produtos vinculados
                  </label>
                  <div className="relative">
                    <select 
                      id="selectProduct"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 text-left appearance-none text-gray-700"
                      onChange={(e) => handleProductSelect(e.target.value)}
                      style={{ backgroundColor: 'white' }}
                    >
                      <option value="">Selecione um produto</option>
                      {availableProducts.map(product => (
                        <option 
                          key={product.id} 
                          value={product.id}
                          disabled={selectedProducts.some(p => p.id === product.id)}
                        >
                          {`${product.product_code} - ${product.name} (${product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })})`}
                        </option>
                      ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <ChevronDown className="w-4 h-4" />
                    </div>
                  </div>
                </div>
                <div className="space-y-4 mt-4">
                  {selectedProducts.map((product, index) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      onRemove={handleProductRemove}
                      onCampaignPriceChange={handleCampaignPriceChange}
                      onQuantityChange={handleQuantityChange}
                      index={index}
                    />
                  ))}
                </div>
                <button
                  onClick={() => handleMethodChange(null)}
                  className="mt-4 text-sm text-gray-600 hover:text-gray-800"
                >
                  Alterar método de adição
                </button>
              </>
            )}
          </div>
        </form>
        <div className="mt-6 flex justify-end space-x-3">
          <button
            onClick={handleClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            Fechar
          </button>
          <button
            onClick={handleSubmit}
            disabled={loading || isImporting}
            className="px-4 py-2 bg-primary text-primary-foreground hover:bg-primary/90 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Criando...' : 'Criar Campanha'}
          </button>
        </div>
      </div>

      {showToastFailure && <FailureToast message={toastMessage} onClose={() => setShowFailureToast(false)} />}
    </div>
  )
}

const truncateText = (text, maxLength) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
}

const ProductCard = ({ product, onRemove, onCampaignPriceChange, onQuantityChange, index }) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const cardRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.style.animation = 'none'
      void cardRef.current.offsetHeight // trigger reflow
      cardRef.current.style.animation = null
    }
  }, [product])

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isExpanded ? `${contentRef.current.scrollHeight}px` : '0px'
    }
  }, [isExpanded, product])

  const sizeArray = product.size.split(";").map(size => size.trim());
  return (
    <div 
      ref={cardRef}
      className="border border-gray-200 rounded-md p-4 animate-slide-in"
      style={{
        '--animation-order': index,
        animationDelay: `calc(var(--animation-order) * 100ms)`,
        minHeight: '80px',
      }}
    >
      <div 
        className="flex justify-between items-center mb-2 cursor-pointer"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h4 className="font-semibold">
          {product.product_code} - {truncateText(product.name, 30)} - {product.price.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </h4>
        <div className="flex items-center">
          {isExpanded ? <ChevronUp className="w-5 h-5 text-gray-500" /> : <ChevronDown className="w-5 h-5 text-gray-500" />}
          <button 
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(product.id);
            }}
            className="ml-2 px-2 py-1 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div 
        ref={contentRef}
        className="space-y-4 overflow-hidden transition-all duration-300 ease-in-out"
        style={{ maxHeight: isExpanded ? '1000px' : '0px' }}
      >
        <div className="p-0.5">
          <label htmlFor={`campaignPrice-${product.id}`} className="block text-sm font-medium text-gray-700 mb-1">
            Preço da campanha
          </label>
          <input 
            type="text"
            id={`campaignPrice-${product.id}`}
            value={product.campaignPrice}
            onChange={(e) => onCampaignPriceChange(product.id, e.target.value)}
            placeholder="R$ 0,00"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-200 focus:border-transparent pr-4"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">Quantidades por tamanho</label>
          <div className="grid grid-cols-3 gap-2 p-0.5">
            {sizeArray.map(size => (
              <div key={size}>
                <label htmlFor={`quantity-${product.id}-${size}`} className="block text-xs font-medium text-gray-700">
                  {size.toUpperCase()}
                </label>
                <input 
                  type="number"
                  id={`quantity-${product.id}-${size}`}
                  value={product.quantities[size] || ''}
                  onChange={(e) => onQuantityChange(product.id, size, e.target.value)}
                  placeholder="0"
                  className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-200 focus:border-transparent"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignModal