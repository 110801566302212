import React, { useState, useEffect } from 'react'
import HeaderProducts from '../components/Header/HeaderProducts';
import TableProducts from '../components/Table/TableProducts';
import SuccessToast from '../components/Toast/SuccessToast'
import axios from 'axios';
import { apiUrl } from '../config';

export default function ProductsPage() {
  const [products, setTableProducts] = useState([]);
  const [showToastSuccess, setShowSuccessToast] = useState(false)
  const [toastMessage, setToastMessage] = useState('')

  useEffect(() => {
    // Simulando o carregamento dos produtos
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${apiUrl}/products/get_products`);
        setTableProducts(response.data);
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    };

    fetchProducts();
  }, []);

  const addProduct = (newProduct) => {
    setToastMessage('Produto salvo com sucesso!');
    setShowSuccessToast(true);
    setTableProducts((prevProducts) => [...prevProducts, newProduct]);
  };

  const handleAddProduct = (newProduct) => {
    setTableProducts((prevProducts) => {
      if (newProduct.id) {
        // Atualiza o produto existente
        return prevProducts.map((product) =>
          product.id === newProduct.id ? newProduct : product
        );
      } else {
        // Adiciona um novo produto
        return [...prevProducts, newProduct];
      }
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <HeaderProducts onAddProduct={addProduct} />
      <main className="flex-grow overflow-hidden p-4">
        <div className="h-full rounded-lg border border-dashed border-gray-300">
            <TableProducts products={products} onAddProduct={handleAddProduct}/>
        </div>
      </main>
      {showToastSuccess && <SuccessToast message={toastMessage} onClose={() => setShowSuccessToast(false)}/>}
    </div>
  );
}
