import React from 'react';

export default function DualMetricCard({ title, quantity, value }) {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
      <h3 className="text-lg font-medium mb-2 text-gray-700">{title}</h3>
      <div className="flex flex-col space-y-1">
        <div className="flex items-center justify-between">
          <span className="text-gray-600">Quantidade:</span>
          <span className="text-xl font-semibold">
            {quantity.toLocaleString('pt-BR')}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-gray-600">Valor:</span>
          <span className="text-xl font-semibold text-green-600">
            {formatCurrency(value)}
          </span>
        </div>
      </div>
    </div>
  );
} 